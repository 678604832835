<!--  -->
<template>
  <div class="applyCar">
    <van-form class="main-wrapper" ref="publishNotice">
      <div class="select-box">
        <van-field
          required
          readonly
          disabled
          name="cartCode"
          v-model="platingNumber"
          label="车辆"
          right-icon="arrow"
        >
        </van-field>

        <van-field
          required
          readonly
          clickable
          name="beginTime"
          v-model="form.beginTime"
          label="实际开始时间"
          placeholder="请选择实际开始时间"
          right-icon="arrow"
          @click="showBeginTime = true"
        />
        <van-popup v-model="showBeginTime" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            title="选择年月"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="beginTimeConfirm"
            @cancel="showBeginTime = false"
          />
        </van-popup>

        <van-field
          required
          readonly
          clickable
          v-model="form.endTime"
          name="endTime"
          label="实际结束时间"
          placeholder="请选择实际结束时间"
          @click="showEndTime = true"
          right-icon="arrow"
        />
        <van-popup v-model="showEndTime" position="bottom">
          <van-datetime-picker
            v-model="currentDate"
            type="datetime"
            title="选择年月"
            :min-date="minDate"
            :max-date="maxDate"
            @confirm="endTimeConfirm"
            @cancel="showEndTime = false"
          />
        </van-popup>
        <van-field
          v-model="form.destination"
          name="destination"
          label="停车位置"
          placeholder="请输入停车位置"
          maxlength="50"
          required
        />
        <van-field
          v-model="form.endFuelQuantity"
          name="endFuelQuantity"
          label="结束油量%"
          placeholder="请输入结束油量百分比"
          required
          maxlength="3"
        />

        <van-field
          v-model="form.endMileage"
          name="endMileage"
          label="结束里程"
          placeholder="请输入结束里程"
          required
          maxlength="6"
        />

        <van-field
          required
          readonly
          clickable
          name="useCard"
          v-model="useCardName"
          label="借用油卡"
          placeholder="请选择是否借用油卡"
          @click="showCardType = true"
        >
        </van-field>

        <van-field
          v-if="1 == useCard"
          required
          readonly
          clickable
          name="cardNo"
          v-model="cardName"
          label="选择油卡"
          placeholder="请选择油卡"
          @click="showCardNo = true"
        >
        </van-field>

        <van-field
          v-if="1 == useCard"
          v-model="form.useMoney"
          name="useMoney"
          label="本次支付金额"
          placeholder="请输入本次支付金额"
          required
          maxlength="9"
        />

        <van-field
          v-if="1 == useCard"
          v-model="form.balanceMoney"
          name="balanceMoney"
          label="卡内剩余金额"
          placeholder="请输入卡内剩余金额"
          required
          maxlength="9"
        />

        <div class="card">
          <div class="sub-title">结束备注</div>
          <textarea
            v-model="form.destinationRemark"
            cols="30"
            rows="5"
            class="text-area"
            maxlength="100"
          ></textarea>
        </div>
        <div class="upload-box">
          <div class="sub-title">结束凭证</div>
          <van-uploader
            v-model="fileList2"
            :max-size="10 * 1024 * 1024"
            :before-read="beforeRead"
            :after-read="afterRead"
            @oversize="onOversize"
            class="upload"
            :max-count="3"
          />
        </div>
      </div>
    </van-form>

    <div class="bottom">
      <div class="button" @click="submit">提交</div>
    </div>
  </div>
</template>

<script>
import { getMyUnReturnList, returnCar } from "@/api/cart/manage/index.js";
import addImg from "@/assets/images/applyCar/add.jpg";
import pickStaff from "@/components/pickStaff/index.vue";
import { getAllStaffList } from "@/api/staff/index.js";
import { Toast } from "vant";
import Schema from "async-validator";
import { uploadImgNoPrefix } from "@/api/cdn";
import dayjs from "dayjs";
export default {
  data() {
    return {
      date: "",
      addImg,

      showCarList: false,
      showTimePicker: false,
      showBeginTime: false,
      showEndTime: false,

      minDate: new Date(2023, 0, 1),
      maxDate: new Date(2040, 11, 31),
      currentDate: new Date(),
      carList: [],

      fileList: [],
      fileList2: [],
      form: {
        applyCode: "",
        beginFuelQuantity: "",
        beginMileage: "",
        beginTime: "",
        destination: "",
        destinationImg: "",
        destinationRemark: "",
        endFuelQuantity: "",
        endMileage: "",
        endTime: "",
        origin: "",
        originImg: "",
        originRemark: "",

        useMoney: "",

        balanceMoney: "",
      },
      useCard: "",
      platingNumber: "",
      useCardName: "",
      beginTime: "",
      endTime: "",
      rules: {
        beginTime: [
          {
            required: true,
            message: "请选择实际开始时间",
          },
        ],
        endTime: [
          {
            required: true,
            message: "请选择实际结束时间",
          },
        ],

        destination: [
          {
            required: true,
            message: "请输入停车位置",
          },
        ],
        endFuelQuantity: [
          {
            required: true,
            message: "请输入结束油量",
          },
          {
            pattern: /^([1-9][0-9]{0,1}|100)$/,
            message: "结束油量百分比必须为100以内正整数",
          },
        ],
        endMileage: [
          {
            required: true,
            message: "请输入结束里程",
          },
          {
            pattern: /^[1-9][0-9]*$/,
            message: "结束里程必须为正整数",
          },
        ],
      },
    };
  },
  name: "applyCar",
  computed: {
    rulesArr() {
      if (1 == this.useCard) {
        return {
          ...this.rules,
          useMoney: [
            {
              required: true,
              message: "请输入本次支付金额",
            },
            {
              pattern:
                /(^[1-9]([0-9]+)?(.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9].[0-9]([0-9])?$)/,
              message: "本次支付金额为非负数，最多保留两位小数",
            },
          ],
          balanceMoney: [
            {
              required: true,
              message: "请输入卡内剩余金额",
            },
            {
              pattern:
                /(^[1-9]([0-9]+)?(.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9].[0-9]([0-9])?$)/,
              message: "卡内剩余金额为非负数，最多保留两位小数",
            },
          ],
        };
      } else {
        return this.rules;
      }
    },
  },
  components: { pickStaff },
  mounted() {
    const endTime = dayjs().format("YYYY-MM-DD HH:mm:ss");

    const beginTime = dayjs(endTime)
      .subtract(30, "minute")
      .format("YYYY-MM-DD HH:mm:ss");
    this.form.beginTime = beginTime;
    this.form.endTime = endTime;
    this.formatDate();
    this.getList();
    let cartCode = this.$route.query.cartCode;
    let platingNumber = this.$route.query.platingNumber;
    if (cartCode && platingNumber) {
      this.platingNumber = platingNumber;
      this.form.cartCode = cartCode;
    }

    this.useCard = this.$route.query.useCard;
    this.useCardName = 1 == this.$route.query.useCard ? "是" : "否";
    this.cardName = this.$route.query.cardName;
    this.cardNo = this.$route.query.cardNo;
    this.form.cardType = this.$route.query.cardType;
  },
  filters: {
    formatePassengerList(val) {
      let arr = val.map((item) => {
        return item.userName;
      });
      return arr.join(",");
    },
  },
  methods: {
    getList() {
      getMyUnReturnList({
        pageNum: 1,
        pageSize: 99,
      }).then((res) => {
        this.carList = res.data.records;
      });
    },
    initValidator() {
      this.validator = new Schema(this.rulesArr);
    },

    carListConfirm(e) {
      this.showCarList = false;
      this.platingNumber = e.platingNumber;
      this.form.cartCode = e.bizCode;
    },
    applyDateConfirm(e) {
      this.showTimePicker = false;
      let year = e.getFullYear();
      let month = e.getMonth() + 1;
      let day = e.getDate();

      this.form.applyDate = `${year}-${String(month).padStart(2, "0")}-${String(
        day
      ).padStart(2, "0")}`;
    },

    formatDate() {
      const date = new Date();
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, "0");
      let day = String(date.getDate()).padStart(2, "0");
      this.date = `${year}-${month}-${day}`;
    },

    beginTimeConfirm(item, index) {
      let year = item.getFullYear();
      let month = String(item.getMonth() + 1).padStart(2, "0");
      let day = String(item.getDate()).padStart(2, "0");
      let hours = String(item.getHours()).padStart(2, "0");
      let minutes = String(item.getMinutes()).padStart(2, "0");
      this.form.beginTime = `${year}-${month}-${day} ${hours}:${minutes}:00`;
      this.showBeginTime = false;
    },
    endTimeConfirm(item, index) {
      let year = item.getFullYear();
      let month = String(item.getMonth() + 1).padStart(2, "0");
      let day = String(item.getDate()).padStart(2, "0");
      let hours = String(item.getHours()).padStart(2, "0");
      let minutes = String(item.getMinutes()).padStart(2, "0");
      this.form.endTime = `${year}-${month}-${day} ${hours}:${minutes}:00`;
      this.showEndTime = false;
    },

    // 返回布尔值
    beforeRead(file) {
      if (["image/jpeg", "image/png"].indexOf(file.type) == -1) {
        Toast("请上传 jpg/png 格式图片");
        return false;
      }
      return true;
    },
    onOversize(file) {
      Toast("文件大小不能超过 10M");
    },
    afterRead(file) {
      let params = new FormData();
      params.append("file", file.file);
      Toast.loading({
        message: "文件上传中...",
        forbidClick: true,
        duration: 0,
      });
      uploadImgNoPrefix(params)
        .then((res) => {
          Toast.clear();
          Toast("上传成功");
          file.resData = res.data;
          // console.log(this.fileList);
        })
        .catch((e) => {
          Toast.clear();
          Toast("上传失败");
        });
    },

    submit() {
      let originImgArr = this.fileList.map((item) => {
        return item.resData.fileCode;
      });
      let destinationImgArr = this.fileList2.map((item) => {
        return item.resData.fileCode;
      });
      let destinationImg = destinationImgArr.join(",");
      let originImg = originImgArr.join(",");

      let postData = {
        ...this.form,
        destinationImg,
        originImg,
        applyCode: this.$route.query.applyCode,
      };
      this.initValidator();

      this.validator
        .validate(postData)
        .then((res) => {
          returnCar(postData).then((res) => {
            Toast("还车成功");
            this.$router.back();
          });
        })
        .catch((e) => {
          Toast(e.errors[0].message);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.applyCar {
  min-height: 100%;
  background: #fafafa;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}
.sub-title {
  color: #646566;
  font-size: 14px;
  margin-bottom: 0.2rem;
}
.van-cell {
  line-height: 35px;
}
.card {
  background: #fff;
  padding: 0.2rem 0.3rem;

  .tag-ul {
    margin-top: 0.2rem;
    margin-bottom: 0.3rem;
  }

  .text-area {
    background: #f6fafe;
    border-radius: 0.1rem;
    width: 100%;
    padding: 0.2rem;
  }

  .add-img {
    height: 1rem;
    width: 1rem;
  }
}

.bottom {
  padding: 0.2rem 0.3rem;
  .button {
    width: 6.9rem;
    height: 0.8rem;
    background: #0094ee;
    border-radius: 0.4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 0.26rem;
    color: #ffffff;
    line-height: 0.37rem;
  }
}
.upload-box {
  background: #fff;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
}
</style>
